import { Observable } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'
import { NextApiRequest, NextApiResponse } from 'next'

import {
  EMPLOYER_USER_COOKIE_PREFIX,
  REFRESH_TOKEN_COOKIE_PREFIX,
  isBuildforceServicesAccount,
  refreshSession,
} from '../authenticate'

import { savePageAndSignout } from './http-links'
import { getCookieValue } from './utils'

type NetworkErrorWithStatusCode = NetworkError & {
  statusCode: number
}

export const serverErrorLink = (req: NextApiRequest, res: NextApiResponse) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const error = new Error(message)
        // Get some visibility around errors
        Sentry.captureException(error)

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            2
          )}, Path: ${path}`
        )
      })
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)

      const { statusCode } = networkError as NetworkErrorWithStatusCode

      if (statusCode === 401) {
        console.log('Need to refresh token...')

        if (!req.cookies) {
          throw new Error('No cookies on request object')
        }
        const refreshToken = getCookieValue(
          req.cookies,
          REFRESH_TOKEN_COOKIE_PREFIX
        )
        const employerUserCookie = req.cookies[EMPLOYER_USER_COOKIE_PREFIX]
        if (!employerUserCookie) {
          throw new Error('No employer user cookie found')
        }
        const employerUserJson = JSON.parse(employerUserCookie)

        const employerId = employerUserJson.employer_id || ''

        if (refreshToken) {
          // We don't refresh access for service accounts.
          if (
            isBuildforceServicesAccount({
              email: employerUserJson.email,
              phone: employerUserJson.phone,
            })
          ) {
            console.error('Refresh token not valid')
            throw new Error('Refresh token not valid.')
          }

          // Let's refresh token through async request
          return new Observable((observer) => {
            //@ts-expect-error - `refreshSession` expects AuthContext to set cookies on the response
            refreshSession({ res }, refreshToken, employerId)
              .then((newToken) => {
                console.log('Sucessfully refreshed token')
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    // Re-add old headers
                    ...headers,

                    // Switch out old access token for new one
                    authorization: newToken ? `Bearer ${newToken}` : '',
                  },
                }))
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }

                // Retry last failed request
                forward(operation).subscribe(subscriber)
              })
              .catch((error) => {
                // No refresh or access token available
                // This error is not handled here, and is unlikely to happen
                // This would mean the /token endpoint is down OR the refresh token is bad
                observer.error(error)

                // Unable to refresh token
                console.error('Unable to refresh token')
                Sentry.captureException(error, {
                  extra: {
                    message: 'Unable to refresh token',
                  },
                })
              })
          })
        } else {
          console.error('No refresh token')
          Sentry.captureException(networkError, {
            extra: {
              message: 'No refresh token found',
            },
          })
        }
      }
    }
  })

export const browserErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const error = new Error(message)
      // Get some visibility around errors
      Sentry.captureException(error)

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
          null,
          2
        )}, Path: ${path}`
      )
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)

    const { statusCode } = networkError as NetworkErrorWithStatusCode

    if (statusCode !== 401) {
      // Get some visibility around errors
      Sentry.captureException(networkError)
    }

    if (statusCode === 500) {
      savePageAndSignout()
      Sentry.captureException(networkError, {
        extra: {
          message: 'Unable to fulfill request, redirecting user to sign in',
        },
      })
    }
  }
})
