import { AnalyticsBrowser } from '@segment/analytics-next'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { useEmployerUser } from './use-employer-user'

export const AnalyticsContext =
  React.createContext<AnalyticsBrowser | null>(null)

const writeKey = process.env.NEXT_PUBLIC_SEGMENT_API_KEY as string

const analytics = AnalyticsBrowser.load({ writeKey })

export const AnalyticsProvider = ({ children }: React.PropsWithChildren) => {
  const employerUser = useEmployerUser()
  const { query } = useRouter()

  useEffect(() => {
    async function identifyEmployer() {
      if (employerUser && analytics) {
        console.log('Identifying employer user ', employerUser)
        return analytics.identify(`employer_user:${employerUser.id}`, {
          ...employerUser,
        })
      }
    }

    identifyEmployer()
  }, [employerUser, query.signup])

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const AnonymousAnalyticsProvider = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext)
  if (!result) {
    throw new Error('AnalyticsContext used outside of its Provider!')
  }
  return result
}
