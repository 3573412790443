import React, { useEffect, useState } from 'react'

import { UseCurrentUser, useCurrentUser } from './use-current-user'

export const AuthContext =
  React.createContext<UseCurrentUser['currentUser'] | null>(null)

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { currentUser } = useCurrentUser()
  const [user, setUser] = useState(currentUser)

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser)
    }
  }, [currentUser])

  return (
    <>
      {user ? (
        <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
      ) : null}
    </>
  )
}

// Create an analytics hook that we can use with other components.
export const useEmployerUser = () => {
  const result = React.useContext(AuthContext)
  if (!result) {
    throw new Error('AuthContext used outside of its Provider!')
  }
  return result
}
