import { gql } from '@apollo/client'

import { accountManagerFragment, employerFragment } from './employer'

export const getUser = gql`
  ${employerFragment}
  ${accountManagerFragment}

  query getUser($id: ID!) {
    employerUser(id: $id) {
      id
      firstName
      lastName
      fullName
      phone
      email
      role
      isBuildforceServices
      isPayrollAdmin
      checkEmployeeId
      employerId
      employer {
        ...EmployerFragment
        accountManager {
          ...AccountManagerFragment
        }
      }
    }
  }
`
