import { NextApiRequest, NextApiResponse, NextPageContext } from 'next/types'
import nookies from 'nookies'

import { AuthContext } from '../types/auth-context'

import { buildNamespacedCookieName } from './client/utils'

export const RESTAPI = process.env.NEXT_PUBLIC_RESTAPI

export const ACCESS_TOKEN_COOKIE_PREFIX = 'x-accesstoken'
export const REFRESH_TOKEN_COOKIE_PREFIX = 'x-refreshtoken'
export const EMPLOYER_USER_COOKIE_PREFIX = 'x-employer'
export const CHALLENGE_SESSION_PREFIX = 'x-session'

export const REFRESH_TOKEN_DURATION = 365 * 24 * 60 * 60

/**
 * Returns the cookie key used to get access token.
 */
export function getAccessTokenCookieKey(employerId: string): string {
  return `${ACCESS_TOKEN_COOKIE_PREFIX}-${employerId}`
}
/**
 * Returns the cookie key used to get refresh token.
 */
export function getRefreshTokenCookieKey(employerId: string): string {
  return `${REFRESH_TOKEN_COOKIE_PREFIX}-${employerId}`
}

/**
 * Checks if a user is a Buildforce Services acccount
 */
export function isBuildforceServicesAccount({
  email = '',
  phone = '',
}: {
  email: string
  phone: string
}): boolean {
  const isBuildforceEmail = email.endsWith('@buildforce.com')

  // Buildforce services phone number example `+1512555XXXX`
  const isBuildforceServicesPhone = phone.substring(5, 8) === '555'

  return isBuildforceEmail && isBuildforceServicesPhone
}

// @deprecated Access token is looked up in Apollo client
export function getAccessToken(
  cookies: Partial<Record<string, string>>,
  contractorId: string
): string {
  const key = getAccessTokenCookieKey(contractorId)
  console.log(
    `Getting access token with key: ${key}, using contractorId: ${contractorId}`
  )
  const token = cookies[key]

  if (!token) throw new Error('Access token not found')
  console.log('Access token found')
  return token
}

type ChallengeResponse =
  | {
      token: string | null
    }
  | { message: string; status: number }

export async function startChallenge(
  req: NextApiRequest,
  res: NextApiResponse
): Promise<ChallengeResponse> {
  try {
    const cookies = nookies.get({ req })
    const token = req.query.a as string
    const phone = req.query.phone as string
    const email = req.query.email as string

    nookies.destroy({ res }, CHALLENGE_SESSION_PREFIX, { path: '/' })
    for (const cookieName in cookies) {
      if (cookieName.startsWith('x-employer')) {
        nookies.destroy({ res }, cookieName, {
          path: '/',
        })
      }
    }

    const getBody = () => {
      if (token) return { token }
      if (phone) return { phone }
      if (email) return { email }
    }

    const response = await fetch(`${RESTAPI}/challenge`, {
      method: 'POST',
      body: JSON.stringify(getBody()),
    })

    if (response.headers.has('x-challengesession')) {
      const challengeSession = response.headers.get('x-challengesession')

      if (!challengeSession) {
        throw new Error('No challenge header found')
      }

      console.log('Setting challenge cookie')
      nookies.set({ res }, CHALLENGE_SESSION_PREFIX, challengeSession, {
        maxAge: 60 * 2, // 2 minutes
        path: '/',
      })

      const body = await response.json()

      nookies.set(
        { res },
        EMPLOYER_USER_COOKIE_PREFIX,
        JSON.stringify(body.user),
        {
          maxAge: REFRESH_TOKEN_DURATION,
          path: '/',
        }
      )

      return { token: null }
    } else if (response.headers.has('x-accesstoken')) {
      // Legacy auth flow will return access tokens instead of a challenge
      console.log('Has access token, setting values')
      const accessToken = response.headers.get('x-accesstoken')
      const expiresIn = response.headers.get('x-tokenexpiresin')
      const refreshToken = response.headers.get('x-refreshtoken')
      const body = await response.json()
      console.log(
        'Challenge token values',
        !!accessToken,
        !!expiresIn,
        !!refreshToken,
        body
      )

      clearAuthenticationCookies(req, res)
      setAuthenticationCookies(
        { res },
        body.user.employer_id,
        // @ts-expect-error Not sure how to resolve this. Should probably consult with Mark
        refreshToken,
        accessToken,
        expiresIn,
        body.user
      )
      return { token: accessToken }
    } else if (response.status > 400 && response.status < 500) {
      console.log('User not found or not authorized.')
      return {
        message: response.statusText,
        status: response.status,
      }
    } else {
      console.log('No session or access token')
      throw new Error('Could not start challenge session')
    }
  } catch (error) {
    console.error('Error initiating token fetch', error)
    throw error
  }
}

export async function refreshSession(
  context: AuthContext,
  refreshToken: string,
  employerId: string
): Promise<string> {
  console.log('Refreshing token...')
  const authData = await fetch(`${RESTAPI}/token`, {
    method: 'POST',
    body: JSON.stringify({
      refreshToken,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  })

  // Save token information
  const accessToken = authData.headers.get('x-accesstoken')
  const expiresIn = authData.headers.get('x-tokenexpiresin')

  // Save access token
  if (accessToken) {
    nookies.set(context, getAccessTokenCookieKey(employerId), accessToken, {
      maxAge: expiresIn ? expiresIn : 30 * 24 * 60,
      path: '/',
    })
    return accessToken
  } else {
    throw new Error('No access token returned')
  }
}

interface EmployerUserInfo {
  id: string
  employer_id: string
  email: string
  phone: string
}

export interface EmployerUser {
  /**
   * ID of the employer user
   */
  id: string
  employerName: string
  employerId: string
  phone: string
  email: string
  firstName: string
  lastName: string
}

/**
 * Uses the short token from a NextPageContext and attempts to read and parse
 * the employer user cookie.
 */
export function getEmployerUser(context: AuthContext): EmployerUser | null {
  const cookies = nookies.get(context)
  console.log('Checking for cookie with key: ', EMPLOYER_USER_COOKIE_PREFIX)
  const employerUserJson = cookies[EMPLOYER_USER_COOKIE_PREFIX]

  if (!employerUserJson) return null

  const employerUser = JSON.parse(decodeURIComponent(employerUserJson))

  return {
    id: employerUser.id.toString(),
    employerName: employerUser.employer_name,
    employerId: employerUser.employer_id.toString(),
    phone: employerUser.phone,
    email: employerUser.email,
    firstName: employerUser.first_name,
    lastName: employerUser.last_name,
  }
}

/**
 *
 * @param context: NextPageContext
 * @returns accessToken: string
 *
 *  authenticate is a method that returns an access token as fast as it can
 *
 *  1. From the `x-accesstoken` cookie (fastest)
 *  2. Using the `x-refreshtoken` cookie if they are a return visitor and not a services account user (~300ms latency)
 *  3. Using the psuedo-token from the URL, the `?a` query param (~500-750ms latency)
 */
async function authenticate(context: AuthContext) {
  const cookies = nookies.get(context)

  const employerUserJson = cookies[EMPLOYER_USER_COOKIE_PREFIX]

  // If employer not found in cookies, return nothing back to kick off auth
  if (!employerUserJson) {
    // want to check for generic access token
    const genericAccessToken = cookies[ACCESS_TOKEN_COOKIE_PREFIX]
    if (genericAccessToken) {
      console.log('Found generic access token')
      return genericAccessToken
    }

    return null
  } else {
    // Parse employer cookie
    const employerUser = JSON.parse(employerUserJson) as EmployerUserInfo

    // Found an access token, short circuit
    const accessTokenKey = getAccessTokenCookieKey(employerUser.employer_id)
    console.log('Checking for access token with key: ', accessTokenKey)
    const accessToken = cookies[accessTokenKey]
    if (accessToken) {
      return accessToken
    }

    // No access token, but I found a refresh token,
    // if this is not a services account, hit the server with refresh token and save
    const refreshTokenKey = getRefreshTokenCookieKey(employerUser.employer_id)
    console.log('Checking for refresh token with key: ', refreshTokenKey)
    const refreshToken = cookies[refreshTokenKey]
    if (
      refreshToken &&
      !isBuildforceServicesAccount({
        email: employerUser.email,
        phone: employerUser.phone,
      })
    ) {
      const token = await refreshSession(
        context,
        refreshToken,
        employerUser.employer_id
      )
      return token
    } else {
      // Remove residual invalid refresh token on a services account
      const cookieName = buildNamespacedCookieName(
        cookies,
        REFRESH_TOKEN_COOKIE_PREFIX
      )

      if (cookieName) {
        nookies.destroy(context, cookieName, { path: '/' })
      }
    }
  }
  return null
}

export async function handleAuthenticateProperties(context: AuthContext) {
  console.log('Requesting page', context.resolvedUrl)
  const token = await authenticate(context)
  const query = context.query
  if (!token) {
    // Signout page is an authenticated page, so it will always redirect to `sign in` with the `next` param as the `/signout` URL if the user isn't authenticated
    // This breaks the sign in redirect flow and doesn't let BF services account users in automatically
    // If the user isn't authenticated, we can just push them to the sign in and the challenge will kick off normally
    if (context.resolvedUrl.includes('/signout')) {
      return {
        redirect: {
          permanent: false,
          destination: context.query.next || '/projects',
        },
      }
    }

    return {
      redirect: query.a
        ? {
            permanent: false,
            destination: `/signin?a=${
              query.a
            }&action=send&next=${encodeURIComponent(context.resolvedUrl)}`,
          }
        : {
            permanent: false,
            destination: `/signin?action=send&next=${encodeURIComponent(
              context.resolvedUrl
            )}`,
          },
    }
  }
  return {
    props: {
      token,
    },
  }
}

export function getServerSideProps(
  context: NextPageContext & {
    query: {
      a?: string
      ppid: string
    }
    resolvedUrl: string
  }
) {
  return handleAuthenticateProperties(context)
}

/**
 * OTP verification
 */
interface OtpChallengeResponse {
  successful: boolean
}
interface FailedOtpChallenge extends OtpChallengeResponse {
  message: string
  sessionToken?: string
}
interface SuccessfulOtpChallengeResponse extends OtpChallengeResponse {
  tokens: {
    accessToken: string
    expiresIn: string
    refreshToken: string
  }
  user: EmployerUser
}

type VerifyOtpChallengeResponse =
  | SuccessfulOtpChallengeResponse
  | FailedOtpChallenge

export async function verifyOtpChallenge(
  otp: string,
  session: string,
  username: string
): Promise<VerifyOtpChallengeResponse> {
  console.log('Verifying...', otp)

  const params = new URLSearchParams()
  params.append('username', username)
  params.append('otpChallengeResponse', otp)

  const response = await fetch(`${RESTAPI}/token`, {
    method: 'POST',
    body: JSON.stringify({
      otp,
      session,
      username,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  })
  console.log('Verify OTP Response', response)

  const body = await response.json()

  // Get token information
  const accessToken = response.headers.get('x-accesstoken')
  const expiresIn = response.headers.get('x-tokenexpiresin')
  const refreshToken = response.headers.get('x-refreshtoken')
  // a session token would only be here for a failed attempt, but where a retry is permittable
  const sessionToken = response.headers.get('x-challengesession')

  if (accessToken && expiresIn && refreshToken) {
    return {
      successful: true,
      user: body.user,
      tokens: {
        accessToken,
        expiresIn,
        refreshToken,
      },
    }
  } else if (sessionToken) {
    return {
      successful: false,
      message: 'Attempt failed but retry permitted',
      sessionToken,
    }
  } else if (response.status === 400) {
    console.log(response.headers, response.headers.get('x-session'))
    return {
      successful: false,
      ...body,
    }
  } else {
    console.log('What is this?', response.headers)
    throw new Error('Unable to verify OTP challenge')
  }
}

export function setAuthenticationCookies(
  context: { res: NextApiResponse },
  employerId: string,
  refreshToken: string,
  accessToken: string,
  expiresIn: string,
  user: EmployerUser
) {
  nookies.set(context, getAccessTokenCookieKey(employerId), accessToken, {
    maxAge: expiresIn ? expiresIn : 30 * 24 * 60,
    path: '/',
  })

  // Services accounts will not get a refresh token back
  !!refreshToken &&
    nookies.set(context, getRefreshTokenCookieKey(employerId), refreshToken, {
      maxAge: REFRESH_TOKEN_DURATION,
      path: '/',
    })

  nookies.set(context, EMPLOYER_USER_COOKIE_PREFIX, JSON.stringify(user), {
    maxAge: REFRESH_TOKEN_DURATION,
    path: '/',
  })
}

export function clearAuthenticationCookies(
  req: NextApiRequest,
  res: NextApiResponse
) {
  const cookies = nookies.get({ req })
  for (const cookieName in cookies) {
    if (
      cookieName.startsWith('x-refreshtoken') ||
      cookieName.startsWith('x-accesstoken') ||
      cookieName.startsWith('x-session') ||
      cookieName.startsWith('x-employer')
    ) {
      nookies.destroy({ res }, cookieName, { path: '/' })
    }
  }
}
