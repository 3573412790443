import { NextApiRequestCookies } from 'next/dist/server/api-utils'

import { EMPLOYER_USER_COOKIE_PREFIX } from '../authenticate'

export const getCookieValue = (
  cookies: NextApiRequestCookies,
  cookiePrefix: string
): string | undefined => {
  // TODO: REMOVE THIS EVENTUALLY
  const employerUserCookie = cookies[EMPLOYER_USER_COOKIE_PREFIX]
  if (!employerUserCookie) {
    console.warn('No employer user cookie found')
  }

  const employerId = employerUserCookie
    ? JSON.parse(decodeURIComponent(employerUserCookie)).employer_id
    : ''

  console.log(
    `Getting cookie with key: ${cookiePrefix}, using contractorId: ${employerId}`
  )

  const cookieName = employerId ? `${cookiePrefix}-${employerId}` : cookiePrefix
  const value = cookies[cookieName]

  if (value) {
    console.log(`Found ${cookiePrefix} cookie`)
  } else {
    console.log(`${cookiePrefix} cookie not found`)
  }

  return value
}

export const buildNamespacedCookieName = (
  cookies: { [key: string]: string },
  cookieName: string
): string | undefined => {
  const employerUserCookie = cookies[EMPLOYER_USER_COOKIE_PREFIX]
  if (!employerUserCookie) return undefined

  const employerId = JSON.parse(
    decodeURIComponent(employerUserCookie)
  ).employer_id

  return `${cookieName}-${employerId}`
}
