import { setContext } from '@apollo/client/link/context'
import { parseCookies } from 'nookies'

import { ACCESS_TOKEN_COOKIE_PREFIX } from '../authenticate'

import { getCookieValue } from './utils'

export const browserAuthLink = setContext((_, { headers }) => {
  const cookies = parseCookies()
  const token = getCookieValue(cookies, ACCESS_TOKEN_COOKIE_PREFIX)

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const serverAuthLink = (token: string | undefined) =>
  setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
