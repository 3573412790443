import { gql } from '@apollo/client'

export const employerFragment = gql`
  fragment EmployerFragment on Employer {
    id
    name
    markupRate
    verifiedAt
    trialStartedAt
    checkCompanyId
    staffCode
    checkCompanyId
    payrollEnabled
    discount {
      percentOff
      endAt
    }
  }
`

export const accountManagerFragment = gql`
  fragment AccountManagerFragment on AccountManager {
    id
    slug
    firstName
    lastName
    phone
    profileImageUrl
    email
  }
`

export const getEmployer = gql`
  ${employerFragment}
  ${accountManagerFragment}

  query getEmployer($id: ID!) {
    employer(id: $id) {
      ...EmployerFragment
      accountManager {
        ...AccountManagerFragment
      }
    }
  }
`

export const UPDATE_EMPLOYER_MUTATION = gql`
  ${employerFragment}

  mutation updateEmployer($id: ID!, $input: EmployerInput!) {
    employerUpdate(id: $id, input: $input) {
      ...EmployerFragment
    }
  }
`
