// Authentication Link Example from https://www.apollographql.com/docs/react/recipes/authentication.html#Header

import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { NextApiRequest, NextApiResponse } from 'next'
import nookies from 'nookies'

import { ACCESS_TOKEN_COOKIE_PREFIX } from '../authenticate'

import { browserAuthLink, serverAuthLink } from './auth-link'
import { serverErrorLink, browserErrorLink } from './error-link'
import * as httpLinks from './http-links'
import { getCookieValue } from './utils'

export const GRAPHAPI = process.env.NEXT_PUBLIC_GRAPHAPI

export const getBrowserClient = () => {
  console.log('Getting browser client')
  console.log('Connecting to', GRAPHAPI)

  return new ApolloClient({
    link: from([browserErrorLink, browserAuthLink, httpLinks.browser]),
    cache: new InMemoryCache(),
  })
}

export const getServerClient = (req: NextApiRequest, res: NextApiResponse) => {
  console.log('Getting server client')
  console.log('Connecting to', GRAPHAPI)

  const cookies = nookies.get({ req })

  const accessToken: string | undefined = getCookieValue(
    cookies,
    ACCESS_TOKEN_COOKIE_PREFIX
  )

  return new ApolloClient({
    link: from([
      serverErrorLink(req, res),
      serverAuthLink(accessToken),
      httpLinks.server,
    ]),
    cache: new InMemoryCache(),
  })
}
