import { createHttpLink } from '@apollo/client'
import { parseCookies } from 'nookies'

import {
  ACCESS_TOKEN_COOKIE_PREFIX,
  EMPLOYER_USER_COOKIE_PREFIX,
  REFRESH_TOKEN_COOKIE_PREFIX,
  RESTAPI,
  isBuildforceServicesAccount,
} from '../authenticate'

import { buildNamespacedCookieName, getCookieValue } from './utils'

const GRAPHAPI = process.env.NEXT_PUBLIC_GRAPHAPI

const TOKEN_ENDPOINT = `${RESTAPI}/token`
const BLOCKED_REFRESH_RETRY_MS = 2000

export const server = createHttpLink({
  uri: GRAPHAPI,
})

export const browser = createHttpLink({
  uri: GRAPHAPI,
  fetch: customFetch,
})

let refreshing = false
function isRefreshing() {
  return refreshing
}

// Create customFetch function for handling re-authorization
// This customFetch (or any fetch you pass to the link) gets uri and options as arguments. We'll use those when we actually execute a fetch.
async function customFetch(
  uri: RequestInfo | URL,
  options: any
): Promise<Response> {
  // Send initial request to the graph and proceed if ok
  const initialResponse = await fetch(uri, options)
  if (initialResponse.ok) return initialResponse

  // If we receive a 401, kick off our refresh token flow
  if (initialResponse.status === 401) {
    const refreshToken = getCookieOrSignout('refreshToken')

    // If a refresh is currently in progress, set an arbitrary timeout and retry the original operation.
    // This eventually can get more complex but for now we kept it simple.
    if (isRefreshing()) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const accessToken = getCookieOrSignout('accessToken')

          options.headers.authorization = `Bearer ${accessToken}`

          const response = await fetch(uri, options)
          return resolve(response)
        }, BLOCKED_REFRESH_RETRY_MS)
      })
    }

    // If we are not currently refreshing a token, go ahead
    // and proceed with a refresh and retry.
    refreshing = true
    const response = await fetch(TOKEN_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
      headers: {
        'Content-type': 'application/json',
      },
    })

    // If re-authorization request has failed, prompt the user to sign in
    if (!response.ok) {
      refreshing = false
      savePageAndSignout()
      throw new Error('Failed to refresh token, redirecting user to sign in')
    }

    // Save the new access token to cookies, then unlock so we can be sure that
    // future requests pull the newest cookie from storage.
    const { accessToken, expiresIn } = await response.json()
    setAccessTokenCookie(accessToken, expiresIn)
    refreshing = false

    // Set the authorization header on the original options parameter to the new access token we got
    options.headers.authorization = `Bearer ${accessToken}`

    // Return the promise from the new fetch (which should now have used an active access token)
    return fetch(uri, options)
  }

  // We received an error but it was not a 401, return response.
  return initialResponse
}

/**
 * Redirects the user to the signout page with current page as the next param
 * @note Browser only
 */
export function savePageAndSignout() {
  if (window === undefined) {
    throw new Error('#savePageAndSignout called in a non-browser setting.')
  }

  // If a user has gotten here, they are likely in a bad state. Clear their access cookie if it exists.
  const cookies = parseCookies()
  for (const cookieName in cookies) {
    if (cookieName.startsWith(ACCESS_TOKEN_COOKIE_PREFIX)) {
      document.cookie = `${cookieName}=;max-age=0`
    }
  }

  // Build signout url
  const currentPage = window.location.href
  const signoutHref = !currentPage.includes('signout')
    ? `/signout?next=${currentPage}`
    : '/signout'
  window.location.href = signoutHref
}

/**
 * Saves a new access token to the browser.
 *
 * Depends on parseCookies from nookies.
 */
function setAccessTokenCookie(accessToken: string, expiresIn: number): void {
  const cookies = parseCookies()
  const cookieName = buildNamespacedCookieName(
    cookies,
    ACCESS_TOKEN_COOKIE_PREFIX
  )

  // If undefined, the cookie name could not be generated because the employer user
  // cookie was not found. Go ahead and sign the user out.
  if (!cookieName) savePageAndSignout()

  window.document.cookie = `${cookieName}=${accessToken};max-age=${expiresIn}`
}

/**
 * Attempts to get a cookie, if not found, redirects the user to singout.
 */
function getCookieOrSignout(type: 'accessToken' | 'refreshToken'): string {
  const name =
    type === 'accessToken'
      ? ACCESS_TOKEN_COOKIE_PREFIX
      : REFRESH_TOKEN_COOKIE_PREFIX

  const cookies = parseCookies()
  const cookieValue = getCookieValue(cookies, name)
  const employerUser = cookies[EMPLOYER_USER_COOKIE_PREFIX]
  const employerUserJson = employerUser ? JSON.parse(employerUser) : {}

  // We do not support automatic refresh on service accounts
  const shouldSignout =
    type === 'refreshToken' &&
    isBuildforceServicesAccount({
      email: employerUserJson.email,
      phone: employerUserJson.phone,
    })

  if (shouldSignout) {
    savePageAndSignout()
    throw new Error('Unable to refresh services account access.')
  } else if (!cookieValue) {
    savePageAndSignout()
    const message = `No ${type} found for ${
      employerUserJson.email || 'unknown user'
    }, forcing sign out.`
    throw new Error(message)
  }

  return cookieValue
}
