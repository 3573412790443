import { useQuery } from '@apollo/client'

import { getUser } from '../queries/user'
import { GetUserQuery, GetUserQueryVariables } from '../types/graphql/types'

export interface UseCurrentUser {
  /**
   * Current employer user data
   */
  currentUser: GetUserQuery['employerUser'] | undefined
  /**
   * Whether or not the hook is loading data.
   */
  isLoading: boolean
}

/**
 * Use the current user.
 */
export function useCurrentUser(): UseCurrentUser {
  const { data, error, loading } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(getUser, {
    variables: { id: 'me' },
  })

  return {
    currentUser: data?.employerUser || undefined,
    isLoading: !data && !error && loading,
  }
}
