import { ApolloProvider } from '@apollo/client'
import { Amplify } from 'aws-amplify'
import { AppProps } from 'next/app'
import { parseCookies } from 'nookies'
import React from 'react'

import { amplifyConfig } from '../config/amplifyConfig'
import {
  AnalyticsProvider,
  AnonymousAnalyticsProvider,
} from '../hooks/use-analytics'
import { AuthProvider } from '../hooks/use-employer-user'
import { ACCESS_TOKEN_COOKIE_PREFIX } from '../utils/authenticate'
import { getBrowserClient } from '../utils/client/client'

import '../styles/globals.css'
import '../styles/_tailwind.css'

Amplify.configure(amplifyConfig)

const ContractorApp = ({
  Component,
  pageProps,
}: AppProps<{ employerId?: string; token?: string }>) => {
  const getAccessTokenFromCookie = () => {
    if (!pageProps.token && typeof window !== 'undefined') {
      const cookies = parseCookies()

      // find the cookie that starts with x-accesstoken- and use that as the token

      for (const cookieName in cookies) {
        if (cookieName.startsWith(ACCESS_TOKEN_COOKIE_PREFIX)) {
          return cookies[cookieName]
        }
      }
    }
  }

  const token = pageProps.token ?? getAccessTokenFromCookie()

  if (!token) {
    return (
      <AnonymousAnalyticsProvider>
        <Component {...pageProps} />
      </AnonymousAnalyticsProvider>
    )
  }

  return (
    <ApolloProvider client={getBrowserClient()}>
      <AuthProvider>
        <AnalyticsProvider>
          <Component {...pageProps} />
        </AnalyticsProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default ContractorApp
